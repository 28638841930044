/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://mqpqsgs755e6jplacgnni3rf7u.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-west-1:1bec1173-68be-4284-81cc-84529ccab5a2",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_U6BNuIDJO",
    "aws_user_pools_web_client_id": "5h5p586hbon9ebeq2ka9gek4aa",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "slalriskukadviser-20230804213740-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2477xr4yqt0bp.cloudfront.net"
};


export default awsmobile;
