/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestions = /* GraphQL */ `
  query GetQuestions {
    getQuestions {
      id
      text
      answers {
        id
        text
        __typename
      }
      __typename
    }
  }
`;
export const getUploadLogoUrl = /* GraphQL */ `
  query GetUploadLogoUrl($input: UploadLogoInput!) {
    getUploadLogoUrl(input: $input) {
      url
      __typename
    }
  }
`;
